// @flow

import useAxios from "axios-hooks";
import { navigate } from "gatsby";
import React, { type Node, Fragment, useEffect, useState } from "react";

import { Permissions } from "../../api/permissions";
import { UpdateProject } from "../../containers";
import { usePermissions } from "../../hooks";
import NotFoundPage from "../404";

type Props = {
    id: string,
};

const ProjectUpdate = ({ id }: Props): Node => {
    const [canManage] = usePermissions([Permissions.PROJECT_MANAGE]);

    if (!id) {
        return <NotFoundPage />;
    }

    const [projectId, setProjectId] = useState();
    const [{ data }, getProjectId] = useAxios({}, { manual: true });

    useEffect(() => {
        if (data) {
            navigate(`/projects/update/${data}`);
        }
    }, [data]);

    useEffect(() => {
        const values = (id || []).split("-");
        if (values.length === 2) {
            getProjectId(
                `/ams-api/project/company/${values[0]}/project-number/${values[1]}/projectId`,
            );
        } else {
            setProjectId(id);
        }
    }, [id]);

    return (
        <Fragment>
            {projectId && (
                <UpdateProject projectId={projectId} readOnly={!canManage} />
            )}
        </Fragment>
    );
};

export default ProjectUpdate;
